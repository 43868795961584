<template>
  <div class="platform">
    <div class="container-bg">
      <p>
        <span class="ball1"></span>
      </p>
      <p>
        <span class="ball2"></span>
      </p>
      <p>
        <span class="ball3"></span>
      </p>
    </div>
    <div class="platform-container">
      <div class="header">
        <div class="left">
          <section>
            <h3>{{ realTime }}</h3>
            <p>
              {{ date }}
              <span>星期{{ weekSign }}</span>
            </p>
          </section>
        </div>
        <div class="center">
          <section>
            <img src="../../assets/images/home/solutions.png" alt srcset />
          </section>
          <section>
            <h3>英海瑞特「ITS」服务平台</h3>
            <p>INHERITECH INTELLIGENT TRANSPOORTATION SYSTEM</p>
          </section>
        </div>
        <div class="right">
          <section>
            <img src="../../assets/images/home/header.png" alt srcset />
          </section>
          <section>
            <p>{{ realname }}</p>
            <p @click="confirmLogout">
              <i class="iconfont its_tuichu"></i>退出
            </p>
          </section>
        </div>
      </div>
      <div class="main">
        <div class="item item1">
          <div class="block">
            <el-carousel
              indicator-position="none"
              :interval="5000"
              height="53vh"
            >
              <el-carousel-item v-for="item in newList" :key="item.id">
                <div>
                  <img :src="item.image" @click="toUrl(item.url)" alt srcset />
                </div>
                <div class="news">
                  <p @click="toUrl(item.url)" :title="item.title">
                    {{ item.title }}
                  </p>
                  <p @click="toUrl(item.url)" :title="item.detail">
                    {{ item.detail }}
                  </p>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="newsMore">
            <p>
              <span>新闻动态</span>
              <span>
                <i class="iconfont its_icon_more"></i>
              </span>
            </p>
          </div>
        </div>
        <div class="item item2" @click="toSystem(1)">
          <section>
            <p>
              <span>
                <img src="../../assets/images/home/home1.png" alt srcset />
              </span>
            </p>
            <h3>世畅纬佳车辆监控平台</h3>
          </section>
        </div>
        <div class="item item3" @click="toSystem(2)">
          <section>
            <p>
              <span>
                <img src="../../assets/images/home/home2.png" alt srcset />
              </span>
            </p>
            <h3>公交主动安全智能调度系统</h3>
          </section>
        </div>
        <div class="item item4" @click="toSystem(3)">
          <section>
            <p>
              <span>
                <img src="../../assets/images/home/home3.png" alt srcset />
              </span>
            </p>
            <h3>出租车服务平台</h3>
          </section>
        </div>
        <div class="item item5" @click="toSystem(4)">
          <section>
            <p>
              <span>
                <img src="../../assets/images/home/home4.png" alt srcset />
              </span>
            </p>
            <h3>资源管理</h3>
          </section>
        </div>
        <div class="item item6">
          <section>
            <p>
              <span>
                <img src="../../assets/images/home/home5.png" alt srcset />
              </span>
            </p>
            <h3>功能待开放…</h3>
          </section>
        </div>
      </div>
      <div class="footer">Copyright © 2020 四川英海瑞特科技有限公司</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { loginOut, queryNews } from '@/api/lib/api.js'
import { formatDay, menuRecursion } from '@/common/utils/index.js'
export default {
  data() {
    return {
      date: formatDay(new Date()),
      newList: [],
      realname: null,
      realTime: null,
      timer: null
    }
  },

  computed: {
    weekSign() {
      const week = new Date().getDay()
      let W = ''
      switch (week) {
        case 0:
          W = '天'
          break
        case 1:
          W = '一'
          break
        case 2:
          W = '二'
          break
        case 3:
          W = '三'
          break
        case 4:
          W = '四'
          break
        case 5:
          W = '五'
          break
        case 6:
          W = '六'
          break
        default:
          break
      }
      return W
    }
  },
  methods: {
    toUrl(url) {
      window.open(url)
    },
    queryNews() {
      queryNews()
        .then((res) => {
          if (res.code === 1000) {
            this.newList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getTime() {
      const d = new Date().valueOf()
      return moment(d).format('HH:mm')
    },
    /** 跳转系统 */
    toSystem(key) {
      switch (key) {
        case 1:
          this.getAllMenu('gps', 'projectGps/')
          break
        case 2:
          this.getAllMenu('bus', 'projectBus/')
          break
        case 3:
          this.getAllMenu('taxi', 'projectTaxi/')
          break
        case 4:
          this.getAllMenu('resourse', 'projectResourse/')
          break
        default:
          break
      }
    },
    /* 动态获取菜单 */
    getAllMenu(key, menu) {
      const self = this
      let menuList = JSON.parse(sessionStorage.getItem('menuList')).subMenuTrees
      let length = menuList.length
      let i = 0
      menuList.forEach((element) => {
        if (element.url === key) {
          if (element.subMenuTrees) {
            const activeMenu = menuRecursion(element.subMenuTrees[0])
            self.$router.push(menu + activeMenu.url)
          } else {
            this.$message.warning('无权限，请联系管理员！')
          }
        } else {
          i++
        }
      })
      if (length == i) {
        this.$message.warning('无权限，请联系管理员！')
      }
    },
    confirmLogout() {
      loginOut({ token: sessionStorage.getItem('token') })
        .then((res) => {
          if (res.code === 1000) {
            localStorage.removeItem('vuex')
            this.$store.dispatch('clear_all')
            this.$store.dispatch('clear_MenuCtr')
            this.$store.dispatch('clear_user')
            this.$store.dispatch('clear_Menus')
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('username')
            sessionStorage.removeItem('realname')
            this.$router.push({ path: '/login' })
            this.$yhsdp.CloseSocketLink()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  mounted() {
    // 页面加载完后显示当前时间
    this.queryNews()
    this.realTime = this.getTime()
    this.realname = sessionStorage.getItem('realname')
    this.timer = setInterval(() => {
      this.realTime = this.getTime()
    }, 1000)
  },

  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>

<style lang="scss" scoped>
.platform {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #8d5783;
  background: -webkit-linear-gradient(
    left,
    #8d5783,
    #0056fd
  ); /* Safari 5.1-6.0 */
  background: -o-linear-gradient(right, #8d5783, #0056fd); /* Opera 11.1-12.0 */
  background: -moz-linear-gradient(
    right,
    #8d5783,
    #0056fd
  ); /* Firefox 3.6-15 */
  background: linear-gradient(to right, #8d5783, #0056fd); /* 标准语法 */
  position: relative;
  @include themify() {
    .container-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      @keyframes bounce1 {
        from {
          transform: scale3d(0.84, 0.85, 0.87) translate3d(0, 0, 0);
        }
        to {
          transform: scale3d(0.9, 0.9, 0.9) translate3d(-90px, 30px, -40px);
        }
      }
      @keyframes bounce2 {
        from {
          transform: scale3d(0.9, 0.9, 0.9) translate3d(0, 0, 0);
        }
        to {
          transform: scale3d(0.84, 0.85, 0.87) translate3d(-40px, -40px, 200px);
        }
      }
      @keyframes bounce3 {
        from {
          transform: scale3d(0.84, 0.85, 0.87) translate3d(-40px, -40px, 40px);
        }
        to {
          transform: scale3d(0.8, 0.8, 0.8) translate3d(0, 0, 0);
        }
      }
      .ball1 {
        animation: bounce1 2.5s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
      }
      .ball2 {
        animation: bounce2 2s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
      }
      .ball3 {
        animation: bounce3 3.5s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
      }
      & > p {
        position: relative;
        width: 30%;
        span {
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.1);
          position: absolute;
        }
      }
      & > p:nth-child(1) {
        span {
          width: 35vw;
          height: 35vw;
          top: -10vw;
          left: -2vw;
        }
      }
      & > p:nth-child(2) {
        span {
          width: 30vw;
          height: 30vw;
          top: 5vw;
          left: -13vw;
        }
      }
      & > p:nth-child(3) {
        flex: 1;
        span {
          width: 30vw;
          height: 30vw;
          bottom: -5vw;
          right: -11vw;
        }
      }
    }
    .platform-container {
      width: 80%;
      margin: 0 auto;
      color: #fff;
      z-index: 4;
      position: absolute;
      left: 10%;
      top: 0;
      .header {
        height: 17vh;
        margin-top: 10vh;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        .left {
          width: 6%;
          section {
            h3 {
              font-size: (60rem/14);
              font-weight: 400;
              line-height: (45rem/14);
              margin: 0 0 1vh 0;
            }
            p {
              font-size: (14rem/14);
              line-height: (15rem/14);
              margin: 0;
              display: flex;
              justify-content: space-around;
              width: 9vw;
            }
          }
        }
        .center {
          width: 70%;
          display: flex;
          align-items: center;
          section {
            height: 9vh;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            img {
              height: 100%;
            }
            h3 {
              width: 100%;
              font-size: (36rem/14);
              font-weight: 400;
              margin: 0;
            }
            p {
              width: 100%;
              color: #f0f0f0;
              font-size: (14rem/14);
              letter-spacing: 1.11px;
              margin: 0;
            }
          }
        }
        .right {
          width: 10%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          section {
            margin-right: 1vh;
            p:nth-child(1) {
              font-size: (16rem/14);
            }
            p:nth-child(2) {
              font-size: (12rem/14);
              margin-top: 1vh;
              cursor: pointer;
              i {
                font-size: 12px;
              }
            }
          }
          section:nth-child(1) {
            width: 4vh;
            height: 4vh;
            border-radius: 50%;
            img {
              width: 100%;
            }
          }
        }
      }
      .main {
        height: 60vh;
        display: grid;
        grid-auto-columns: 5fr 5fr 4fr 4fr;
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
          'a b c c'
          'a d e f';
        grid-gap: 20px 20px;
        .item {
          padding: 1.5vh;
          overflow: hidden;
          border-radius: 4px;
          box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.1);
          & > section {
            width: 100%;
            height: 100%;
            & > p {
              height: 50%;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: flex-end;
              & > span {
                height: 4rem;
                width: 4rem;
                border-radius: 50%;
                img {
                  width: 100%;
                }
              }
            }
            & > h3 {
              width: 100%;
              font-size: 1.5rem;
              font-weight: normal;
              margin: 1vh 0;
              text-align: center;
            }
          }
        }
        .item1 {
          grid-area: a;
          background-color: #336ffe;
          padding: 0;
          & > div {
            img {
              width: 100%;
            }
          }
          /deep/.el-carousel__arrow {
            background: rgba(0, 0, 0, 0.4);
            top: 70%;
          }
          .news {
            padding: 1.5vh;
            & > p:nth-child(1) {
              font-size: (22rem/14);
              font-weight: 600;
              line-height: (24rem/14);
              margin-top: 1vh;
              cursor: pointer;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }
            & > p:nth-child(2) {
              font-size: (14rem/14);
              line-height: (26.25rem/14);
              margin-top: 2.5vh;
              margin-bottom: 10vh;
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3; /* 可以显示的行数，超出部分用...表示*/
              -webkit-box-orient: vertical;
            }
          }
          .newsMore {
            padding: 1.5vh;
            p {
              display: flex;
              justify-content: space-between;
              align-items: center;
              & > :nth-child(1) {
                font-size: (22rem/14);
                line-height: (30rem/14);
              }
            }
          }
        }
        .item2 {
          grid-area: b;
          background-color: #336ffe;
        }
        .item3 {
          grid-area: c;
          background-color: #ea853e;
        }
        .item4 {
          grid-area: d;
          background-color: #4fb638;
        }
        .item5 {
          grid-area: e;
          background-color: #9154e7;
        }
        .item6 {
          grid-area: f;
          background-color: #ee524f;
        }
      }
      .footer {
        height: 10vh;
        line-height: 10vh;
        text-align: center;
      }
    }
  }
}
</style>
